import React, { } from 'react'
import { Button, Box } from '@mui/material';
import './multiChoices.css';
import { saveResWidget } from '../../utils/utils';


const MultiChoices = (props) => {
    const { setState, payload, actionProvider } = props
    const {choices, id} = payload;

    // console.log(choices)

    // const form = props.children.props.state.messages.filter(f=> f.type === "widget");
    // console.log(form[form.length-1])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {choices.map((item, index) => <Button onClick={() =>  {saveResWidget(((item.text ? item.value : item)), setState); actionProvider.next(item.next || id+1)}} key={index} variant="outlined">{(item.text || item)}</Button> )}
        </Box>
    )
}

export default MultiChoices
