export const sortScoreArray = (lst, n) => {

    let arr = []
    Object.keys(lst).forEach((key)=> {
        arr.push(
            {
                key: key.substring(10).toUpperCase(),
                final: lst[key].final
            }
        )
    })
    arr.sort(function(a, b) {
        return a.final - b.final;
      });
      arr.reverse()
      return arr
}
