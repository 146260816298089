export const Langage = {
    "welcome": {
        "french": "Bienvenue sur Cactus. En utilisant cette plateforme le son doit etre active",
        "english": "Welcome to Sam Tech. Using this platform sound must be enabled"
    },
    "start": {
        "french": "Bonjour ! Je suis ton assistant personnel, une IA conçue pour déterminer tes besoins et les produits adaptés. Mais avant, faisons connaissance ! Comment t’appelles-tu ? ",
        "english": "Hi ! I am your personal assistant, an AI designed to determine your needs and the right products. But first, let's get to know each other! What's your name ?"
    },
    "age": {
        "french": "quel âge as-tu ?",
        "english": "How old are you?"
    },
    "gender": {
        "french": "Es-tu une femme, un homme ou ne souhaites-tu pas te définir ?",
        "english": "Are you a woman, a man or do you not want to define yourself?"
    },
    "woman": {
        "french": "Femme",
        "english": "Woman"
    },
    "man": {
        "french": "Homme",
        "english": "Man"
    },
    "not_define": {
        "french": "Non-binaire",
        "english": "None"
    },
    "start_experience": {
        "french": "Démarrons l’expérience ! Nous allons analyser tes problématiques de peau grâce à une analyse par IA, diagnostic validé par des dermatologues.",
        "english": "Let's start the experience ! We will analyze your skin problems thanks to an AI analysis, a diagnosis validated by dermatologists."
    },
    "start_experience_hair": {
        "french": "Démarrons l’expérience !",
        "english": "Let's start the experience !"
    },
    "determine": {
        "french": "Nous avons déterminé que votre peau est à ",
        "english": "We have determined that your skin is "
    },
    "today_analysis": {
        "french": "D'après le selfie, voici votre peau aujourd'hui :",
        "english": "Based on the selfie, this is your skin today:"
    },
    "i_will_propose": {
        "french": "Je vais te proposer des produits qui répondent aux besoins de ta peau. Clique sur les besoins que tu souhaites prioriser ci-dessous",
        "english": "I will suggest products that meet the needs of your skin. Click on the needs you want to prioritize below"
    },
    "here_your_analysis": {
        "french": "Voici les résultats de votre analyse",
        "english": "Here are the results of your analysis"
    },
    "here_your_presc": {
        "french": "Voici votre prescription",
        "english": "Here is your prescription"
    },
    "continue": {
        "french": "continue 2em partie",
        "english": "continue part 2"
    },
    "i_choose_type": {
        "french": "Je choisis mon type de peau",
        "english": "I choose my skin type"
    },
    "have_alergy": {
        "french": "As-tu des allergies ?",
        "english": "Do you have allergies?"
    },
    "with_one": {
        "french": "Si oui lesquelles ?",
        "english": "If so which ?"
    },
    "yes": {
        "french": "Oui",
        "english": "yes"
    },
    "nope": {
        "french": "Non",
        "english": "No"
    },
    "rides_indique": {
        "french": "Peux-tu m'indiquer où sont situées tes rides ?",
        "english": "Can you tell me where your wrinkles are located?"
    },
    "eye_circle": {
        "french": "Autour de l'oeil",
        "english": "around the eye"
    },
    "in_front": {
        "french": "Sur le front",
        "english": "On the forehead"
    },
    "bouche_circle": {
        "french": "Autour de la bouche",
        "english": "around the mouth"
    },
    "rides_sourc": {
        "french": "Entre les sourcils (ride du lion)",
        "english": "Between the eyebrows (frown lines)"
    },
    "partout": {
        "french": "Partout",
        "english": "Everywhere"
    },
    "want_relachement": {
        "french": "Voudrais-tu travailler sur le relâchement cutané ?",
        "english": "Would you like to work on sagging skin?"
    },
    "i_want_relachement": {
        "french": "Je souhaites travailler sur le relâchement cutané",
        "english": "I want to work on sagging skin"
    },
    "eczema_concern": {
        "french": "Es-tu concerné·e par de l'eczéma ou de la rosacée ?",
        "english": "Do you have eczema or rosacea?"
    },
    "i_m_eczema_concern": {
        "french": "Je suis concerné par de l'eczéma ou de la rosacée",
        "english": "I have eczema or rosacea"
    },
    "product_correspondent": {
        "french": "Parmi ces produits, lesquels te correspondent ?",
        "english": "Which of these products are right for you?"
    },
    "requilibrant": {
        "french": "Rééquilibrant",
        "english": "Rebalancing"
    },
    "none_sulfate": {
        "french": "Sans sulfate",
        "english": "Sulfate free"
    },
    "purifiant": {
        "french": "Purifiant",
        "english": "purifying"
    },
    "matifiant": {
        "french": "Matifiant",
        "english": "Mattifying"
    },
    "boutons_indique": {
        "french": "Peux-tu m'indiquer où sont situés tes boutons ?",
        "english": "Can you tell me where your spots are located?"
    },
    "zone_t": {
        "french": "Zone T",
        "english": "T Zone"
    },
    "in_joues": {
        "french": "Sur les joues",
        "english": "On the cheeks"
    },
    "locale": {
        "french": "Localisés",
        "english": "Localized"
    },
    "see_boutons": {
        "french": "Ces boutons sont-ils présents ?",
        "english": "Are these spots present?"
    },
    "permanence": {
        "french": "En permanence",
        "english": "Always"
    },
    "episod": {
        "french": "De manière épisodique",
        "english": "Occasionally"
    },
    "remove_taches": {
        "french": "Quel type de tâches souhaites-tu éliminer / réduire ?",
        "english": "What kind of stains do you want to eliminate/reduce?"
    },
    "rouss_taches": {
        "french": "Tâches de rousseur",
        "english": "Freckles"
    },
    "old_taches": {
        "french": "Tâches de vieillesse",
        "english": "Age spots"
    },
    "vin_taches": {
        "french": "Tâches de vin",
        "english": "wine stains"
    },
    "birth_taches": {
        "french": "Tâches de naissance",
        "english": "birthmarks"
    },
    "pregnant_taches": {
        "french": "Tâches de grossesse",
        "english": "Pregnancy stains"
    },
    "hydrate": {
        "french": "Quelles sont les manifestations de la déshydratation cutanée ?",
        "english": "What are the signs of skin dehydration?"
    },
    "teint_terne": {
        "french": "Teint terne",
        "english": "Dull complexion"
    },
    "stries": {
        "french": "Stries",
        "english": "skin streaks"
    },
    "souplesse": {
        "french": "Manque de souplesse",
        "english": "Lack of skin suppleness"
    },
    "traillements": {
        "french": "Tiraillements",
        "english": "Skin tightness"
    },
    "demangeaisons": {
        "french": "Démangeaisons",
        "english": "Itching"
    },
    "visible_pores": {
        "french": "Tes pores sont-ils toujours visibles ou seulement à certains moments de la journée ? ",
        "english": "Are your pores always visible or only at certain times of the day?"
    },
    "never": {
        "french": "Jamais",
        "english": "Never"
    },
    "some_time": {
        "french": "Parfois",
        "english": "Sometimes"
    },
    "always": {
        "french": "Toujours",
        "english": "Always"
    },
    "day_period": {
        "french": "Dépendant du moment de la journée",
        "english": "Depending on the time of day"
    },
    "pores_light": {
        "french": "Pores légèrement apparents",
        "english": "Slightly visible pores"
    },
    "pores_exposed": {
        "french": "Pores apparents",
        "english": "Exposed pores"
    },
    "pores_sometimes": {
        "french": "Pores parfois apparents",
        "english": "Sometimes visible pores"
    },
    "cicatrice_on_face": {
        "french": "As-tu des cicatrices sur le visage ? ",
        "english": "Do you have any scars on your face?"
    },
    "none": {
        "french": "Aucune",
        "english": "None"
    },
    "some": {
        "french": "Quelques-unes",
        "english": "Some"
    },
    "a_lot": {
        "french": "Beaucoup",
        "english": "A lot"
    },
    "pores_scars": {
        "french": "J'ai des cicatrices sur le visage",
        "english": "I have scars on my face"
    },
    "pores_scars_alot": {
        "french": "J'ai beaucoup de cicatrices sur le visage",
        "english": "I have a lot of scars on my face"
    },
    "pollue_city": {
        "french": "Vis-tu dans un environnement pollué ?",
        "english": "Do you live in a polluted environment?"
    },
    "pollue_a_lot": {
        "french": "Très pollué",
        "english": "very polluted"
    },
    "pollue_average": {
        "french": "Moyennement pollué",
        "english": "Moderately polluted"
    },
    "pollue_little": {
        "french": "Peu pollué",
        "english": "Little polluted"
    },
    "none_pollue": {
        "french": "Pas du tout pollué",
        "english": "Not polluted at all"
    },
    "product_interess": {
        "french": "Es-tu intéressé·e par des produits autobronzants ou plutôt par des produits \"coup d'éclat\"",
        "english": "Are you interested in self-tanning products or rather in \"radiance boost\" products?"
    },
    "eclat_coup": {
        "french": "Coup d'éclat",
        "english": "Boost radiance"
    },
    "autbronzant": {
        "french": "Autbronzant",
        "english": "Self tanning"
    },
    "desire_product": {
        "french": "Comment désires-tu ton produit ?",
        "english": "How do you want your product?"
    },
    "vegan": {
        "french": "Vegan",
        "english": "Vegan"
    },
    "bio": {
        "french": "Bio",
        "english": "Bio"
    },
    "natural": {
        "french": "D'origine naturelle",
        "english": "natural origin"
    },
    "made_in_france": {
        "french": "Made in France",
        "english": "Made in France"
    },
    "renseigne_email": {
        "french": "Ta prescription de produits personnalisés est en cours, renseigne ton email pour la reçevoir.",
        "english": "Your personalized product prescription is in progress, fill in your email to receive it."
    },
    "your_products": {
        "french": "Tu ne trouveras dans cette page que les produits qui te correspondent.\nLa peau évolue tous les jours, c’est pour ça que c’est important de lui apporter les actifs essentiels dont elle à besoin à chaque moment.",
        "english": "You will only find on this page the products that suit you The skin evolves every day, that's why it's important to provide it with the essential active ingredients it needs at all times."
    },
    "take_selfie": {
        "french": "Prendre mon selfie",
        "english": "Take my self"
    },
    "my_analysis": {
        "french": "Peau en cours d'analyse",
        "english": "Skin analysis in progress"
    },
    "process_analysis": {
        "french": "Analyse en cours :",
        "english": "Analysis in progress"
    },
    "rides": {
        "french": "Rides",
        "english": "wrinkles"
    },
    "taches": {
        "french": "Taches",
        "english": "stains"
    },
    "imperfections": {
        "french": "Imperfections",
        "english": "Imperfections"
    },
    "sensibilite": {
        "french": "Sensibilité",
        "english": "Sensitivity"
    },
    "hydratation": {
        "french": "Hydratation",
        "english": "Hydration"
    },
    "brillance": {
        "french": "Brillance",
        "english": "Shine"
    },
    "eclat": {
        "french": "Éclat",
        "english": "Glow"
    },
    "pores": {
        "french": "Grain de peau",
        "english": "Skin texture"
    },
    "see_result": {
        "french": "Voir les résultats détaillés",
        "english": "See detailed results"
    },
    "ok": {
        "french": "OK",
        "english": "Ok"
    },
    "i_accept": {
        "french": "J'accepte",
        "english": "I Agree"
    },
    "my_result": {
        "french": "Mes résultats",
        "english": "My results"
    },
    "confirm": {
        "french": "Confirmer",
        "english": "Confirm"
    },
    "presc_on_way": {
        "french": "Élaboration de la \n prescription en cours",
        "english": " prescription in progress"
    },
    "see_our_offer": {
        "french": "En attendant ta \n prescription, découvre \n notre offre exclusive.",
        "english": "Waiting for your prescription, discover  our exclusive offer"
    },
    "i_have_products": {
        "french": "Super, j’ai la sélection de \n  produits qu’il te faut !",
        "english": "Great, I’ve got your products needed selection"
    },
    "see_ma_selection": {
        "french": "Voir ma selection",
        "english": "Discover my prescription"
    },
    "here_your_result": {
        "french": "Voici les résultats de l'analyse de peau",
        "english": "Here are the results of the skin analysis"
    },
    "close": {
        "french": "Fermer",
        "english": "Close"
    },
    "age_q": {
        "french": "Age :",
        "english": "Age :"
    },
    "gender_q": {
        "french": "Genre :",
        "english": "Gender :"
    },
    "today_type": {
        "french": "Voici ton type de peau aujourd'hui :",
        "english": "Here your skin type today :"
    },
    "brief_allergie": {
        "french": "Allergies: ",
        "english": "Allergies :"
    },
    "brief_rides": {
        "french": "Mes rides se situent ",
        "english": "My wrinkles are located "
    },
    "brief_product_correspondent": {
        "french": "Pour éviter la brillance, je recherche un produit ",
        "english": "To avoid shine, I am looking for a product "
    },
    "brief_boutons_found": {
        "french": "Mes boutons se trouvent ",
        "english": "My pimples found at "
    },
    "brief_boutons_present": {
        "french": "Mes boutons sont présents ",
        "english": "My pimples are present "
    },
    "brief_taches": {
        "french": "Je souhaites réduire les ",
        "english": "I want to reduce the "
    },
    "brief_hydrate": {
        "french": "Déshydratation: ",
        "english": "Dehydration: "
    },
    "brief_eclat_env": {
        "french": "Environnement: ",
        "english": "Environment: "
    },
    "brief_eclat": {
        "french": "Pour l'éclat de mon visage je recherche un produit ",
        "english": "For the radiance of my face I am looking for a product "
    },
    "brief_desire_product": {
        "french": "Je souhaite que mon produit soit: ",
        "english": "I want my product to be: "
    },
    "SECHE": {
        "french": `<b>L’analyse est terminée, j’ai fait une étude complète de ton profil :</b>
    <br> Ta peau
    présente des stries, un grain de peau serré et un teint terne, qui sont les signes d’une
    peau à tendance sèche.<br><br>
    <b>Mon conseil :</b><br> élimine régulièrement les cellules mortes par un nettoyant non
    agressif, préfère une huile démaquillante à une eau micellaire, nourrit
    régulièrement ta peau avec des masques adaptés`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br> Your skin
     has streaks, tight skin texture and a dull complexion, which are signs of a
     dry skin.<br><br>
     <b>My advice:</b><br> Regularly remove dead cells with a cleanser not
     aggressive, prefers a cleansing oil to a micellar water, nourishes
     your skin regularly with suitable masks`
    },
    "GRASSE": {
        "french": `<b>L’analyse est terminée, j’ai fait une étude complète de ton profil :</b>
    <br>Ta peau présente des
    pores irréguliers, dilatés avec la présence de points noirs, de boutons, un excès de
    brillance, ce sont les signes d’une peau grasse<br><br>
    <b>Mon conseil :</b> <br>nettoie ta peau régulièrement avec des produits doux, sans alcool et
    sans savon, hydrate la peau , utilise des masques purifiants, évite les produits à base
    d’huile`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>Your skin has
     irregular, dilated pores with the presence of blackheads, pimples, excess
     shine, these are the signs of oily skin<br><br>
     <b>My advice:</b> <br>cleanse your skin regularly with gentle, alcohol-free products
     soap-free, hydrates the skin, uses purifying masks, avoids products containing
     of oil`
    },
    "DESHYDRATEE": {
        "french":  `<b>L’analyse est terminée, j’ai fait une étude complète de ton profil : </b>
    <br>Ta peau présente
    de fines ridules, l’éclat dû teint est légèrement terne, le grain de peau est serré, la
    sensibilité est présente et la peau nécessite un fort besoin d’hydratation, ce sont les
    signes d’une peau déshydratée<br><br>
    <b>Mon conseil :</b> protége ta peau du froid, du soleil et du vent, évite les produits
    détergents, évite la présence de climatisation, privilegie des produits riches en
    lipides`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile: </b>
     <br>Your skin presents
     fine lines, the radiance of the complexion is slightly dull, the skin texture is tight, the
     sensitivity is present and the skin requires a strong need for hydration, these are the
     signs of dehydrated skin<br><br>
     <b>My advice:</b> protect your skin from the cold, the sun and the wind, avoid products
     detergents, avoid the presence of air conditioning, favor products rich in
     lipids`
    },
    "MATURE": {
        "french": `<b>L’analyse est terminée, j’ai fait une étude complète de ton profil :</b>
    <br>Ta peau révèle
    des rides sur les zones d’expression, le teint est terne, on note des stries de
    déshydratation, ce sont les signes d’une peau Mature
    <b>Mon conseil :</b><br><br>utilise des gommages doux 2 fois par semaine, hydrate ta peau
    chaque jour , utilise un soin anti rides le jour et utilise un sérum anti Age et une
    crème repulpant le soir, protège ta peau du soleil et de la pollution`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>Your skin reveals
     wrinkles on the expression areas, the complexion is dull, there are streaks of
     dehydration, these are the signs of mature skin
     <b>My advice:</b><br><br>use gentle scrubs twice a week, moisturize your skin
     every day, use an anti-wrinkle day care and use an anti-aging serum and a
     plumping cream in the evening, protects your skin from the sun and pollution`
    },
    "SENSIBLE": {
        "french": `<b>L’analyse est terminée, j’ai fait une étude complète de ton profil :</b> 
    <br>le type de peau qui
    te correspond est une peau sensible . une peau sensible à une tenddance à la
    reactivité aux produits cosmétiques avec du parfum, des conservateurs, des tensio
    actifs
    <b>Mon conseil :</b><br><br>Évite les gommages agressifs, utilise un écran total, évite le tabac
    l’alcool, protège toi des pollutions, de la climatisation , , sèche ton visage sans
    frotter mais en tamponnant`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>the type of skin that
     corresponds to you is a sensitive skin. sensitive skin with a tendency to
     reactivity to cosmetic products with perfume, preservatives, surfactants
     assets
     <b>My advice:</b><br><br>Avoid harsh scrubs, use sunblock, avoid tobacco
     alcohol, protect yourself from pollution, air conditioning, dry your face without
     rub but dab`
    },
    "ACNEIQUE": {
        "french": `<b>L’analyse est terminée, j’ai fait une étude complète de ton profil :</b>
    <br>Ta peau présente
    de nombreux boutons, des points noirs, les pores sont dilatés, la brillance est
    excessive, ce sont les signes d’une peau Acnéique
    Mon conseil :<br><br> Hydrate régulièrement ta peau, nettoie ta peau matin et soir avec des
    produits doux , sans savon, utilise des crèmes matifiantes pour masquer les défauts`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>Your skin presents
     many pimples, blackheads, pores are enlarged, shine is
     excessive, these are the signs of acne-prone skin
     My advice: <br><br> Moisturize your skin regularly, cleanse your skin morning and evening with
     gentle products, without soap, uses mattifying creams to hide flaws`
    },
    "EQUILIBREE": {
        "french": `<b>L’analyse est terminée, j’ai fait une étude complète de ton profil :</b> 
    <br>Ta peau présente
    de bons scores sur tous les critères étudiés : l’éclat du teint est bon, le grain de peau
    lisse, l’hydratation parfaite, très peu de sensibilité et de brillance, se sont les signes
    d’une peau Équilibrée
    Mon conseil :<br><br> nettoie régulièrement ta peau, hydrate la tous les jours, exfolie les
    peaux mortes 1 à 2 fois par semaine, protège ta peau du soleil`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>Your skin presents
     good scores on all the criteria studied: the radiance of the complexion is good, the skin texture
     smooth, perfect hydration, very little sensitivity and shine, are the signs
     balanced skin
     My advice: <br><br> regularly cleanse your skin, moisturize it daily, exfoliate the
     dead skin 1 to 2 times a week, protect your skin from the sun`
    },
    "MIXTE": {
        "french": `<b>L’analyse est terminée, j’ai fait une étude complète de ton profil :</b> 
    <br>Ta peau présente
    des points noirs sur la zone T, les pores sont dilatés sur la zone T et sont serrés sur le
    reste du visage, tu ressent des tiraillements localisés , le teint est terne sur les joues
    et brillant sur la zone T , se sont les signes d’une peau Mixte
    <b>Mon conseil :</b><br><br>il est important de contrôler l&#39;excès de sébum des zones grasses sans
    dessécher davantage les zones plus sèches. Il est donc conseillé de privilégier des
    produits non gras et d&#39;éviter tous les produits pouvant contenir de l&#39;alcool`,
        "english": `<b>The analysis is complete, I have made a complete study of your profile:</b>
     <br>Your skin presents
     blackheads on the T-zone, the pores are dilated on the T-zone and are tight on the
     rest of the face, you feel localized tightness, the complexion is dull on the cheeks
     and shiny on the T-zone, are the signs of Combination skin
     <b>My advice:</b><br><br>it is important to control excess sebum in oily areas without
     further desiccate drier areas. It is therefore advisable to favor
     non-greasy products and avoiding all products that may contain alcohol`
    }
}

export const LangageHair = {
    "hair_type": {
        "french": "Quel est votre type de cheveux ?",
        "english": "Quel est votre type de cheveux ?"
    },
    "steep": {
        "french": "Raide",
        "english": "Raide"
    },
    "corrugated": {
        "french": "Ondulé",
        "english": "Ondulé"
    },
    "looped": {
        "french": "Bouclé",
        "english": "Bouclé"
    },
    "curly": {
        "french": "Frisé",
        "english": "Frisé"
    },
    "frizzy": {
        "french": "Crépu",
        "english": "Crépu"
    },
    "hair_thickness": {
        "french": "Quelle est l'épaisseur de vos cheveux ?",
        "english": "Quelle est l'épaisseur de vos cheveux ?"
    },
    "fins": {
        "french": "Fins",
        "english": "Fins"
    },
    "normals": {
        "french": "Normaux",
        "english": "Normaux"
    },
    "epais": {
        "french": "Epais",
        "english": "Epais"
    },
    "scalp": {
        "french": "Pouvez-vous preciser la nature de votre cuir chevelu ?",
        "english": "Pouvez-vous preciser la nature de votre cuir chevelu ?"
    },
    "sec": {
        "french": "Sec",
        "english": "Sec"
    },
    "normal": {
        "french": "Normal",
        "english": "Normal"
    },
    "gras": {
        "french": "Gras",
        "english": "Gras"
    },
    "goals": {
        "french": "Quels sont vos objectifs beauté pour vos cheveux ?",
        "english": "Quels sont vos objectifs beauté pour vos cheveux ?"
    },
    "volume": {
        "french": "Volume",
        "english": "Volume"
    },
    "smoothing": {
        "french": "Lissage",
        "english": "Lissage"
    },
    "loops": {
        "french": "Boucles",
        "english": "Boucles"
    },
    "shine": {
        "french": "Brillance",
        "english": "Brillance"
    },
    "hair_care": {
        "french": "Quels sont vos objectifs soin pour vos cheveux ?",
        "english": "\"Quels sont vos objectifs soin pour vos cheveux ?\""
    },
    "growth": {
        "french": "Stimuler la croissance",
        "english": "Stimuler la croissance"
    },
    "strengthen": {
        "french": "Fortifier",
        "english": "Fortifier"
    },
    "appease": {
        "french": "Apaiser",
        "english": "Apaiser"
    },
    "hydrate": {
        "french": "Hydrater",
        "english": "Hydrater"
    },
    "feed": {
        "french": "Nourrir",
        "english": "Nourrir"
    },
    "repair": {
        "french": "Réparer",
        "english": "Réparer"
    },
    "sebum": {
        "french": "Régulation du sébum",
        "english": "Régulation du sébum"
    },
    "product_type": {
        "french": "Quel type de produit souhaitez-vous utiliser ?",
        "english": "Quel type de produit souhaitez-vous utiliser ?"
    },
    "shampoo": {
        "french": "Shampoing",
        "english": "Shampoing"
    },
    "conditioner": {
        "french": "Après-Shampoing",
        "english": "Après-Shampoing"
    },
    "lotion": {
        "french": "Lotion",
        "english": "Lotion"
    },
    "spec_need": {
        "french": "Veuillez preciser si vous avez des besoins spécifiques ?",
        "english": "Veuillez preciser si vous avez des besoins spécifiques ?"
    },
    "colored_hair": {
        "french": "Cheveux colorés",
        "english": "Cheveux colorés"
    },
    "others": {
        "french": "Autres",
        "english": "Autres"
    },
    "brief_type": {
        "french": "Type de cheveux: ",
        "english": "Type de cheveux: "
    },
    "brief_thick": {
        "french": "Epaisseur des cheveux: ",
        "english": "Epaisseur des cheveux: "
    },
    "brief_beauty": {
        "french": "Beauté des cheveux: ",
        "english": "Beauté des cheveux: "
    },
    "brief_soin": {
        "french": "Soin du cheveux: ",
        "english": "Soin du cheveux: "
    },
    "recommend_products": {
        "french": "Super, j’ai la sélection de \n  produits qu’il te faut !",
        "english": "Great, I’ve got your products needed selection"
    },
    "see_selection": {
        "french": "Voir ma selection",
        "english": "See my selection"
    }
}
